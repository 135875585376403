.App {
    padding: 0;
    background-color: #DEECEF;
}

.Header {
    border-bottom: 1px solid #9FCCF0;
    background-color: #DEECEF;
}

.Sidebar {
    width: 120px;
    margin: 5px;
    position: sticky;
    top: 62px;
    align-self: flex-start;
    align-items: start;
}

.Sidebar .nav-item {
    width: 100%;
}

.Sidebar a, .dropdown-item, .dropdown-item.active {
    color: #444;
}

.Sidebar a:hover {
    background-color: #eee;
}

.Sidebar a:visited {
    color: #444;
}

.Sidebar .nav-item .active, .dropdown-item.active {
    background-color: #def;
}

.Content {
    margin-top: 10px;
}

.Post {
    align-items: start;
    padding-top: 5px;
    border-bottom: 1px solid #eee;
}

.Post:hover {
    background-color: #f8f8f8;
}

.Post a {
    color: #14c;
    text-decoration: none;
}

.Post a:visited {
    color: #14c;
}

.More {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: right;
}

.InputField {
    margin-top: 15px;
    margin-bottom: 15px;
}

.Write {
    margin-bottom: 10px;
    padding: 30px 0px 40px 0px;
    border-bottom: 1px solid #eee;
}

.Write form {
    width: 100%;
}

.Body {
    background-color: #DEECEF;
    color: #32628A;
}

.terms-link {
    text-align: right;
    text-decoration: none;
    color: #32628A;
    background-color: #DEECEF;
}

.terms-link:hover {
    text-decoration: underline;
}
.privacy-policy-link {
    text-decoration: underline;
    color: #007bff;
}
